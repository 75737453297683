import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function ReviewScreenOutlined() {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.50293 17.0082V16.4332C8.50293 15.6998 8.8696 15.1123 9.60293 14.6707C10.3363 14.229 11.3029 14.0082 12.5029 14.0082C13.7029 14.0082 14.6696 14.229 15.4029 14.6707C16.1363 15.1123 16.5029 15.6998 16.5029 16.4332V17.0082H8.50293ZM12.5029 13.0082C11.9529 13.0082 11.4821 12.8123 11.0904 12.4207C10.6988 12.029 10.5029 11.5582 10.5029 11.0082C10.5029 10.4582 10.6988 9.98735 11.0904 9.59568C11.4821 9.20401 11.9529 9.00818 12.5029 9.00818C13.0529 9.00818 13.5238 9.20401 13.9154 9.59568C14.3071 9.98735 14.5029 10.4582 14.5029 11.0082C14.5029 11.5582 14.3071 12.029 13.9154 12.4207C13.5238 12.8123 13.0529 13.0082 12.5029 13.0082ZM3.50293 9.20818V16.8582C3.73626 17.0748 4.0196 17.2623 4.35293 17.4207C4.68626 17.579 5.0696 17.6998 5.50293 17.7832V9.85818C5.13626 9.77485 4.7821 9.67901 4.44043 9.57068C4.09876 9.46235 3.78626 9.34151 3.50293 9.20818ZM21.5029 9.18318C21.2196 9.31651 20.9113 9.43735 20.5779 9.54568C20.2446 9.65401 19.8863 9.74985 19.5029 9.83318V17.7832C19.9363 17.6998 20.3196 17.579 20.6529 17.4207C20.9863 17.2623 21.2696 17.0748 21.5029 16.8582V9.18318ZM7.50293 20.1582C5.58626 19.9248 4.1071 19.5332 3.06543 18.9832C2.02376 18.4332 1.50293 17.7748 1.50293 17.0082V7.00818C1.50293 6.05818 2.44876 5.32485 4.34043 4.80818C6.2321 4.29151 8.95293 4.03318 12.5029 4.03318C16.0529 4.03318 18.7738 4.29151 20.6654 4.80818C22.5571 5.32485 23.5029 6.05818 23.5029 7.00818V17.0082C23.5029 17.7748 22.9821 18.4332 21.9404 18.9832C20.8988 19.5332 19.4196 19.9248 17.5029 20.1582V8.15818C18.3529 8.02484 19.1071 7.86235 19.7654 7.67068C20.4238 7.47901 20.8946 7.29151 21.1779 7.10818C20.4613 6.82485 19.2363 6.57068 17.5029 6.34568C15.7696 6.12068 14.1029 6.00818 12.5029 6.00818C10.9029 6.00818 9.23626 6.12068 7.50293 6.34568C5.7696 6.57068 4.5446 6.82485 3.82793 7.10818C4.11126 7.30818 4.5821 7.49985 5.24043 7.68318C5.89876 7.86651 6.65293 8.02484 7.50293 8.15818V20.1582Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

export default ReviewScreenOutlined;
